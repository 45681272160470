import React from "react";

export default function SellerTagline() {
    return (
        <div className="pt-24 px-12" style={{ maxWidth: '1500px', margin: '0 auto' }} data-aos="fade-up">
            <div className="bg-purple rounded-md" style={{ color: 'white', padding: '4rem', textAlign: 'center', transition: 'transform 0.3s' }}>
                <h1 style={{ fontSize: '1.7rem', fontWeight: 'bold', maxWidth: '950px', margin: '0 auto' }}>
                    Elevate Your Dealmaking with AMIVA+
                </h1>
            </div>
        </div>
    );
}
