import React from "react";
import SellerHome from "./SellerHome";
import Strengths from '../general/Strengths';
import HardWay from './hardWay/HardWay';
import SellerEasyWay from "./SellerEasyWay";
import SellerFeatures from "./SellerFeatures";
import SellerCloseDeals from "./SellerCloseDeals";
import SellerCallForAction from "./SellerCallForAction";
import SellerSteps from "./SellerSteps";
import SellerTagline from "./SellerTagline";
import { SpeakerphoneIcon, DocumentTextIcon, RefreshIcon } from '@heroicons/react/outline';


const Seller = () => {

    const features = [
        {
          title: 'Automated Buyer Outreach',
          description: 'Reach more buyers with less effort using our AI-powered system',
          icon: SpeakerphoneIcon,
        },
        {
          title: 'Integrated Document Management',
          description: 'Simplify your deal process with easy document handling',
          icon: DocumentTextIcon,
        },
        {
          title: 'Real-Time CRM Sync',
          description: 'Keep track of every deal in one place, updated in real-time',
          icon: RefreshIcon,
        },
    ];

    return (
        <>
            <SellerHome />
            <Strengths features={features}/>
            <HardWay />
            <SellerEasyWay />
            <SellerCloseDeals />
            <SellerCallForAction />
            <SellerFeatures />
            <SellerSteps />
            <SellerTagline />
        </>
    )
}

export default Seller;
