import React from "react";

export default function SellerSteps() {
    return (
        <div className="bg-[#fafafa] w-full mx-auto px-12 py-24 text-center rounded-lg">
            <h1 className="text-4xl font-semibold" data-aos="fade-up">
                Getting Started is <span style={{ textDecoration: 'underline', textDecorationColor: '#1E90FF' }}>Easy</span> and <span style={{ textDecoration: 'underline', textDecorationColor: '#1E90FF' }}>Free</span>
            </h1>

            <div className="mt-24 flex flex-col items-center">
                <div style={{ width: '3rem', height: '3rem', borderRadius: '50%', backgroundColor: '#8e7fc7', display: 'flex', alignItems: 'center', justifyContent: 'center', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }} data-aos="fade-up">
                    <p style={{ color: 'white', fontSize: '1.25rem', fontWeight: 'bold' }}>1</p>
                </div>
                <p className="text-2xl font-semibold mt-4 text-purple-900" data-aos="fade-up">Start Your Free Trial</p>
                <p className="text-lg mt-2 text-purple-700" data-aos="fade-up">Experience AMIVA+ for free and see how it simplifies your deal management</p>
                <div className="bg-black h-20 w-[1px] mt-4" data-aos="fade-up"></div>
            </div>

            <div className="mt-4 flex flex-col items-center">
                <div style={{ width: '3rem', height: '3rem', borderRadius: '50%', backgroundColor: '#8e7fc7', display: 'flex', alignItems: 'center', justifyContent: 'center', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }} data-aos="fade-up">
                    <p style={{ color: 'white', fontSize: '1.25rem', fontWeight: 'bold' }}>2</p>
                </div>
                <p className="text-2xl font-semibold mt-4 text-purple-900" data-aos="fade-up">Manage Your First Deal</p>
                <p className="text-lg mt-2 text-purple-700" data-aos="fade-up">Easily set up and manage your first deal with our intuitive platform</p>
                <div className="bg-black h-20 w-[1px] mt-4" data-aos="fade-up"></div>
            </div>

            <div className="mt-4 flex flex-col items-center">
                <div style={{ width: '3rem', height: '3rem', borderRadius: '50%', backgroundColor: '#8e7fc7', display: 'flex', alignItems: 'center', justifyContent: 'center', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }} data-aos="fade-up">
                    <p style={{ color: 'white', fontSize: '1.25rem', fontWeight: 'bold' }}>3</p>
                </div>
                <p className="text-2xl font-semibold mt-4 text-purple-900" data-aos="fade-up">Close More Deals, Work Less</p>
                <p className="text-lg mt-2 text-purple-700" data-aos="fade-up">Achieve greater success with less effort using AMIVA+</p>
            </div>

            <a className="inline-flex items-center gap-2 px-6 py-3 bg-gradient-to-r from-black to-gray-800 rounded-full cursor-pointer group hover:shadow-lg hover:scale-105 transition border-2 border-transparent hover:duration-300 duration-300 mt-24 text-white" 
               href="https://app.amivaplus.com/register?trial=new&token=PuMG3da3LG0lD93100lkPQP" target="_blank" rel="noreferrer">
                <p className="font-semibold">Try Now</p>
                <i className='bx bx-chevron-right'></i>
            </a>
        </div>
    );
}
