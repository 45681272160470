import React from "react";
import Typewriter from "../general/TypeWriter";

export default function SellerHome() {
    return (
        <div className="relative h-screen p-6 w-full bg-purple background-image-container">
            <div className="flex flex-col items-center max-w-container mx-auto text-white h-full justify-center p-8">
                <div className="text-left w-full ">
                    <div className="">
                        <Typewriter text={'Find Better Buyers\nClose Deals Faster'} speed={25}/>
                    </div>
                    <p className="mt-6 text-xl home-sm:text-base">Leverage AI to connect with pre-vetted buyers and manage deals seamlessly. Boost success by up to 40%</p>
    

                    <div className="mt-8">
                        <div className="flex gap-4">
                            <a href="https://app.amivaplus.com/register?token=PuMG3da3LG0lD93100lkPQP" target="_blank" rel="noreferrer">
                                <div className="inline-flex items-center gap-2 px-6 py-3 bg-gradient-to-r from-black to-gray-800 rounded-full cursor-pointer group hover:shadow-lg hover:scale-105 transition border-2 border-transparent hover:duration-300 duration-300">
                                    <p className="font-semibold text-sm md:text-base text-white">Get free listing</p>
                                    <i className='bx bx-chevron-right text-white '></i>
                                </div>
                            </a>
                          
                        </div>
                        <p className="mt-2 text-sm text-gray-200">*No credit card required</p>
                    </div>
                </div>
            </div>

            {/* <div className="absolute bottom-8 mb-11 left-1/2 transform -translate-x-1/2 cursor-pointer" onClick={() => {
                const targetDiv = document.getElementById('targetDiv');
                targetDiv.scrollIntoView({ behavior: 'smooth' });
            }}>
                <i className='bx bx-chevron-down text-white text-3xl animate-bounce hover:animate-none hover:text-gray-300 transition duration-150'></i>
            </div> */}
        </div>
    );
}
