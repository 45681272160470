import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faClipboardList, faFileAlt, faTasks, faShieldAlt, faChartLine } from '@fortawesome/free-solid-svg-icons';

export default function SellerFeatures() {
    return (
        <div className="max-w-container ml-auto mr-auto px-20 py-24">
            <h1 className="text-4xl font-semibold text-center text-purple-900" data-aos="fade-up">Make managing your deals a breeze!</h1>
            
            <p className="font-semibold opacity-70 text-center mt-20 ml-auto mr-auto text-lg max-w-[500px] justify-center" data-aos="fade-up" >Simple, easy-to-use software that helps you close more deals with less work.</p>

            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mt-20 gap-12">
                <div className="text-center" data-aos="fade-up" >
                    <div className="flex flex-col items-center gap-2">
                        <div className="bg-purple-200 px-4 py-2.5 rounded-full shadow-md hover:bg-purple-300 transition duration-300 ease-in-out transform hover:-translate-y-1">
                            <FontAwesomeIcon icon={faClipboardList} className="text-purple text-2xl" />
                        </div>
                        <p className="font-semibold text-lg mt-2 text-purple-900">Centralized Management</p>
                    </div>

                    <p className="text-base mt-4 opacity-80 text-purple-800">Use AMIVA+ as the backbone of your deal-making process. From initial listing to final closing, manage all listings, buyers, documents, communication, and tracking in one place.</p>
                </div>
                
                <div className="text-center" data-aos="fade-up" >
                    <div className="flex flex-col items-center gap-2">
                        <div className="bg-purple-200 px-[13px] py-2.5 rounded-full shadow-md hover:bg-purple-300 transition duration-300 ease-in-out transform hover:-translate-y-1">
                            <FontAwesomeIcon icon={faCheckCircle} className="text-purple text-2xl" />
                        </div>
                        <p className="font-semibold text-lg mt-2 text-purple-900">Automated Features</p>
                    </div>

                    <p className="text-base mt-4 opacity-80 text-purple-800">Leverage automated confidential listing info, integrated NDAs, and analytics to save time and reduce complexity.</p>
                </div>

                <div className="text-center" data-aos="fade-up" >
                    <div className="flex flex-col items-center gap-2">
                        <div className="bg-purple-200 px-4 py-2.5 rounded-full shadow-md hover:bg-purple-300 transition duration-300 ease-in-out transform hover:-translate-y-1">
                            <FontAwesomeIcon icon={faFileAlt} className="text-purple text-2xl" />
                        </div>
                        <p className="font-semibold text-lg mt-2 text-purple-900">Easy Document Sharing</p>
                    </div>

                    <p className="text-base mt-4 opacity-80 text-purple-800">Share important documents securely and efficiently with permission controls, ensuring everyone has access to the right information.</p>
                </div>

                <div className="text-center" data-aos="fade-up" >
                    <div className="flex flex-col items-center gap-2">
                        <div className="bg-purple-200 px-[13px] py-2.5 rounded-full shadow-md hover:bg-purple-300 transition duration-300 ease-in-out transform hover:-translate-y-1">
                            <FontAwesomeIcon icon={faTasks} className="text-purple text-2xl" />
                        </div>
                        <p className="font-semibold text-lg mt-2 text-purple-900">Task Management</p>
                    </div>

                    <p className="text-base mt-4 opacity-80 text-purple-800">Coordinate effortlessly with brokers, buyers, lawyers, and other stakeholders. Keep everyone on the same page and on track.</p>
                </div>

                <div className="text-center" data-aos="fade-up" >
                    <div className="flex flex-col items-center gap-2">
                        <div className="bg-purple-200 px-[13px] py-2.5 rounded-full shadow-md hover:bg-purple-300 transition duration-300 ease-in-out transform hover:-translate-y-1">
                            <FontAwesomeIcon icon={faShieldAlt} className="text-purple text-2xl" />
                        </div>
                        <p className="font-semibold text-lg mt-2 text-purple-900">Secure Communication</p>
                    </div>

                    <p className="text-base mt-4 opacity-80 text-purple-800">Communicate securely with built-in group chats, keeping all discussions in one place.</p>
                </div>

                <div className="text-center" data-aos="fade-up" >
                    <div className="flex flex-col items-center gap-2">
                        <div className="bg-purple-200 px-[13px] py-2.5 rounded-full shadow-md hover:bg-purple-300 transition duration-300 ease-in-out transform hover:-translate-y-1">
                            <FontAwesomeIcon icon={faChartLine} className="text-purple text-2xl" />
                        </div>
                        <p className="font-semibold text-lg mt-2 text-purple-900">Track Progress</p>
                    </div>

                    <p className="text-base mt-4 opacity-80 text-purple-800">Monitor deal progress with built-in CRM capabilities, making it easy to see where each deal stands and what needs to be done next.</p>
                </div>
            </div>
        </div>
    )
}
