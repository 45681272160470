import React, { useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import CalendarItem from "./CalendarItem";

export default function HardWay() {
    /*
    The Hard Way to Close Sell-Side Mandates

    Prepare and Search

        •	Service Agreement Signed
        •	Manually Assembling Data Room
        •	Redlining NDAs and Contracts by Hand
        •	Manually Gathering and Verifying Client Financials
        •	Tedious Buyer Outreach and Initial Screening

    Present

        •	Creating Marketing Materials from Scratch
        •	Manually Scheduling and Coordinating Management Presentations
        •	Organizing and Executing Roadshows
        •	Labor-Intensive Initial Negotiations and Offers

    Closing & Transition

        •	Manual, Time-Consuming Due Diligence Process
        •	Negotiating and Redlining Definitive Agreements Without Automation
        •	Coordinating Closing and Integration Planning Manually
      
    */
  const containerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const scrollLeftFunction = () => {
    containerRef.current.scrollBy({ left: -1050, behavior: "smooth" });
  };

  const scrollRightFunction = () => {
    containerRef.current.scrollBy({ left: 1050, behavior: "smooth" });
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - containerRef.current.offsetLeft);
    setScrollLeft(containerRef.current.scrollLeft);
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - containerRef.current.offsetLeft;
    const walk = (x - startX) * 20; // Adjust the scroll speed
    containerRef.current.scrollLeft = scrollLeft - walk;
  };

  return (
    <div className="max-w-container ml-auto mr-auto px-12 py-24">
        <div className="relative -top-[120px]" id="targetDiv"> </div>
      <div className="flex items-center gap-2 justify-center" data-aos="fade-up">
        <h1 className="text-3xl font-semibold">
          <span
            style={{
              textDecoration: "underline",
              textDecorationColor: "#fdba74",
            }}
          >
            The hard way
          </span>{" "}
          to close sell-side mandates
        </h1>
      </div>

      <div className="flex items-center">
            <button onClick={scrollLeftFunction} className="bg-white px-3 py-1 mr-3 rounded-full shadow-md hover:bg-gray-100 transition duration-300">
                <FontAwesomeIcon icon={faChevronLeft} />
            </button>
      <div className="flex relative gap-4 mt-12 overflow-auto hide-scroll cursor-all-scroll" 
        ref={containerRef}
        onMouseDown={handleMouseDown}
        onMouseLeave={handleMouseLeave}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
      >
        <div className="section flex-1" id="prepare-search">
          <div className="sticky left-0 w-64 bg-white items-center flex gap-2 mb-14">
            <div className="w-4 h-4 bg-orange-300 rounded-full"></div>
            <p className="text-xl font-semibold">Prepare and search</p>
          </div>
          
          <div className="flex gap-4">
            <CalendarItem name="Service Agreement Signed" numberOfMonths={1} firstMonth={1} color='orange'/>

            <CalendarItem name="Manually Assembling Data Room" numberOfMonths={2} firstMonth={2} color='orange'/>

            <CalendarItem name="Redlining NDAs and Contracts by Hand" numberOfMonths={1} firstMonth={4} color='orange'/>

            <CalendarItem name="Manually Gathering and Verifying Client Financials" numberOfMonths={1} firstMonth={5} color='orange'/>

            <CalendarItem name="Tedious Buyer Outreach and Initial Screening" numberOfMonths={1} firstMonth={6} color='orange'/>
          </div>
        </div>

        <div className="section flex-1" id="present">
          <div className="sticky left-0 w-32 bg-white flex items-center gap-2 mb-14">
            <div className="w-4 h-4 bg-blue-300 rounded-full"></div>
            <p className="text-xl font-semibold">Present</p>
          </div>

          <div className="flex gap-4">
            <CalendarItem name="Creating Marketing Materials from Scratch" numberOfMonths={1} firstMonth={7} color='blue'/>

            <CalendarItem name="Manually Scheduling and Coordinating Management Presentations" numberOfMonths={1} firstMonth={8} color='blue'/>

            <CalendarItem name="Organizing and Executing Roadshows" numberOfMonths={1} firstMonth={9} color='blue'/>

            <CalendarItem name="Labor-Intensive Initial Negotiations and Offers" numberOfMonths={1} firstMonth={10} color='blue'/>
          </div>
        </div>

        <div className="section flex-1" id="closing-transition">
            <div className="sticky left-0 w-64 bg-white flex items-center gap-2 mb-14">
              <div className="w-4 h-4 bg-green-300 rounded-full"></div>
              <p className="text-xl font-semibold">Closing & Transition</p>
            </div>

            <div className="flex gap-4">
              <CalendarItem name="Manual, Time-Consuming Due Diligence Process" numberOfMonths={2} firstMonth={11} color='green'/>

              <CalendarItem name="Negotiating and Redlining Definitive Agreements Without Automation" numberOfMonths={2} firstMonth={1} color='green'/>

              <CalendarItem name="Coordinating Closing and Integration Planning Manually" numberOfMonths={1} firstMonth={2} color='green'/>
            </div>
          </div>

              <div className="sticky right-0 bg-gradient-to-l from-white to-transparent min-w-20 min-h-full text-white"></div>
            </div>
            <button onClick={scrollRightFunction} className="bg-white px-3 py-1 rounded-full shadow-md hover:bg-gray-100 transition duration-300">
                <FontAwesomeIcon icon={faChevronRight} />
            </button>
            </div>
          </div>
  );
}
