import React from 'react'
import { useNavigate } from 'react-router-dom';

export default function PrivacyPolicy({setShow}) {
    const navigate = useNavigate();

    // scroll to top function
    function scrollToTop() {
        const top = document.getElementById('top');

        if (top) {
            top.scrollIntoView({behavior: 'smooth'});
        }
    }

  return (
    <div className={`text-black font-medium text-left
        fixed overflow-auto top-0 left-0 w-full h-screen py-10 z-[200]`}>  

        <div className="fixed top-0 left-0 w-full h-screen bg-black bg-opacity-80" onClick={() => {
            // if route is /privacy-policy, then navigate to /
            if (window.location.pathname === '/privacy-policy') {
                navigate('/')
            }

            setShow(false)
        }}></div>

        <form className={`bg-white absolute left-1/2 p-5 rounded-md w-[880px] -translate-x-1/2 mb-10
        f-lg:w-[560px]`}
        id='top'>
            <div className="bg-gray-100 text-gray-800">
                {/* section 1 */}
                <div className="max-w-6xl mx-auto p-6 bg-white shadow-lg rounded-lg">
                    {/* Introduction Section */}
                    <div className="flex items-start justify-between mb-10">
                        <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>
                        <p className='text-xs text-end leading-3 cursor-pointer hover:opacity-70 duration-200' onClick={() => {
                            // if route is /privacy-policy, then navigate to /
                            if (window.location.pathname === '/privacy-policy') {
                                navigate('/')
                            }
                            
                            setShow(false)
                        }}>Close</p>
                    </div>

                    <p className="mb-4">Last updated September 9, 2024</p>

                    <p className="mb-6">
                    This Privacy Notice for AMIVA+, Inc. ("we," "us," or "our"), describes how and why we
                    might access, collect, store, use, and/or share ("process") your personal information when
                    you use our services ("Services"), including when you:
                    </p>

                    <ul className="list-disc pl-6 mb-6">
                    <li>
                        Visit our website at{' '}
                        <a
                        href="http://www.amivaplus.com"
                        className="text-blue hover:underline"
                        >
                        http://www.amivaplus.com
                        </a>, or any website of ours that links to this Privacy Notice.
                    </li>
                    <li>
                        Use AMIVA+, a deal portal designed to get dealmakers more access to opportunity and
                        streamline workflow.
                    </li>
                    <li>Engage with us in other related ways, including any sales, marketing, or events.</li>
                    </ul>

                    <p className="mb-6">
                    Questions or concerns? Reading this Privacy Notice will help you understand your privacy
                    rights and choices. We are responsible for making decisions about how your personal
                    information is processed. If you do not agree with our policies and practices, please do not use
                    our Services. If you still have any questions or concerns, please contact us at{' '}
                    <a href="mailto:support@amivaplus.com" className="text-blue hover:underline">
                        support@amivaplus.com
                    </a>.
                    </p>

                    {/* Summary of Key Points */}
                    <h2 className="text-2xl font-semibold mb-4">Summary of Key Points</h2>
                    <p className="mb-4">
                    This summary provides key points from our Privacy Notice, but you can find out more details
                    about any of these topics by clicking the link following each key point or by using our table of
                    contents below to find the section you are looking for.
                    </p>

                    <ul className="list-disc pl-6 mb-6">
                    <li>
                        <strong>What personal information do we process?</strong> When you visit, use, or navigate
                        our Services, we may process personal information depending on how you interact with us and
                        the Services, the choices you make, and the products and features you use.
                    </li>
                    <li>
                        <strong>Do we process any sensitive personal information?</strong> Some of the information may
                        be considered "special" or "sensitive" in certain jurisdictions, such as your racial or ethnic
                        origins, sexual orientation, and religious beliefs.
                    </li>
                    <li>
                        <strong>Do we collect any information from third parties?</strong> We do not collect any
                        information from third parties.
                    </li>
                    <li>
                        <strong>How do we process your information?</strong> We process your information to provide,
                        improve, and administer our Services, communicate with you, for security and fraud prevention,
                        and to comply with law.
                    </li>
                    <li>
                        <strong>In what situations and with which parties do we share personal information?</strong> We
                        may share information in specific situations and with specific third parties.
                    </li>
                    <li>
                        <strong>How do we keep your information safe?</strong> We have adequate organizational and
                        technical processes and procedures in place to protect your personal information.
                    </li>
                    <li>
                        <strong>What are your rights?</strong> Depending on where you are located geographically, the
                        applicable privacy law may mean you have certain rights regarding your personal information.
                    </li>
                    <li>
                        <strong>How do you exercise your rights?</strong> The easiest way to exercise your rights is by
                        visiting{' '}
                        <a
                        href="https://app.amivaplus.com/users/my_profile"
                        className="text-blue hover:underline"
                        >
                        https://app.amivaplus.com/users/my_profile
                        </a> or by contacting us.
                    </li>
                    </ul>

                    
                    <p className="text-right text-blue hover:underline" onClick={() => scrollToTop()}>Back to top</p>
                    
                </div>

                {/* section 2 */}
                <div className="bg-gray-100 text-gray-800">
                    <div className="max-w-6xl mx-auto p-6 bg-white shadow-lg rounded-lg">
                        <h2 id="info" className="text-2xl font-semibold mb-4">1. What Information Do We Collect?</h2>
                        <p className="mb-4">We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.</p>

                        <h3 className="text-xl font-semibold mb-4">Personal Information Provided by You</h3>
                        <p className="mb-4">The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:</p>
                        <ul className="list-disc pl-6 mb-6">
                        <li>Names</li>
                        <li>Email addresses</li>
                        <li>Usernames</li>
                        <li>Passwords</li>
                        <li>Contact preferences</li>
                        <li>Contact or authentication data</li>
                        <li>Debit/credit card numbers</li>
                        <li>Billing addresses</li>
                        </ul>

                        <h3 className="text-xl font-semibold mb-4">Sensitive Information</h3>
                        <p className="mb-4">When necessary, with your consent or as otherwise permitted by applicable law, we process the following categories of sensitive information:</p>
                        <ul className="list-disc pl-6 mb-6">
                        <li>Financial data</li>
                        </ul>

                        <h3 className="text-xl font-semibold mb-4">Payment Data</h3>
                        <p className="mb-4">We may collect data necessary to process your payment if you choose to make purchases, such as your payment instrument number and the security code associated with your payment instrument. All payment data is handled and stored by Stripe. You may find their privacy notice here: <a href="https://stripe.com/privacy" className="text-blue hover:underline">https://stripe.com/privacy</a>.</p>

                        <h3 className="text-xl font-semibold mb-4">Social Media Login Data</h3>
                        <p className="mb-4">We may provide you with the option to register with us using your existing social media account details, like your Facebook, X (formerly Twitter), or other social media account. If you choose to register in this way, we will collect certain profile information about you from the social media provider. The profile information we receive may vary depending on the social media provider but will often include your name, email address, friends list, profile picture, and other information you choose to make public on such a platform.</p>

                        <h3 className="text-xl font-semibold mb-4">Information Automatically Collected</h3>
                        <p className="mb-4">Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, and other technical information.</p>
                        <p className="mb-6">This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.</p>

                        <h3 className="text-xl font-semibold mb-4">The information we collect includes:</h3>
                        <ul className="list-disc pl-6 mb-6">
                        <li>
                            <strong>Log and Usage Data:</strong> This is service-related, diagnostic, usage, and performance information our servers automatically collect when you access or use our Services and which we record in log files. This log data may include your IP address, device information, browser type and settings, and information about your activity in the Services (such as date/time stamps, pages viewed, and other actions you take).
                        </li>
                        <li>
                            <strong>Device Data:</strong> We collect device data such as information about your computer, phone, tablet, or other devices used to access the Services. Depending on the device, this device data may include your IP address, device and application identification numbers, browser type, hardware model, Internet service provider or mobile carrier, operating system, and system configuration information.
                        </li>
                        <li>
                            <strong>Location Data:</strong> We collect location data such as information about your device's location, which can be either precise or imprecise. The amount of information we collect depends on the type and settings of the device you use to access the Services. For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP address). You can opt out of allowing us to collect this information either by refusing access to the information or by disabling your location setting on your device.
                        </li>
                        </ul>

                        
                        <p className="text-right text-blue hover:underline" onClick={() => scrollToTop()}>Back to top</p>
                        
                    </div>
                </div>

                {/* section 3 */}
                <div className="bg-gray-100 text-gray-800">
                    <div className="max-w-6xl mx-auto p-6 bg-white shadow-lg rounded-lg">
                        <h2 id="process" className="text-2xl font-semibold mb-4">2. How Do We Process Your Information?</h2>
                        <p className="mb-4">
                        We process your personal information to provide, improve, and administer our Services,
                        communicate with you, for security and fraud prevention, and to comply with law. We may
                        also process your information for other purposes with your consent.
                        </p>

                        <h3 className="text-xl font-semibold mb-4">We process your personal information for a variety of reasons, including:</h3>
                        <ul className="list-disc pl-6 mb-6">
                        <li>
                            <strong>To facilitate account creation and authentication and manage user accounts:</strong> We may process your information to allow you to create and log in to your account, and to keep your account operational.
                        </li>
                        <li>
                            <strong>To respond to user inquiries and offer support:</strong> We may process your information to respond to your inquiries and address any potential issues.
                        </li>
                        <li>
                            <strong>To enable user-to-user communications:</strong> If you use any of our offerings that allow for communication with another user, we may process your information for this purpose.
                        </li>
                        <li>
                            <strong>To send marketing and promotional communications:</strong> We may process the personal information you send to us for our marketing purposes, if this is in accordance with your marketing preferences. You can opt out of our marketing emails at any time.
                        </li>
                        <li>
                            <strong>To protect our Services:</strong> We may process your information as part of our efforts to keep our Services safe and secure, including fraud monitoring and prevention.
                        </li>
                        <li>
                            <strong>To identify usage trends:</strong> We may process information about how you use our Services to better understand how they are being used so we can improve them.
                        </li>
                        <li>
                            <strong>To comply with our legal obligations:</strong> We may process your information to comply with our legal obligations, respond to legal requests, and exercise, establish, or defend our legal rights.
                        </li>
                        </ul>

                        
                        <p className="text-right text-blue hover:underline" onClick={() => scrollToTop()}>Back to top</p>
                        
                    </div>
                </div>
                    
                {/* section 4 */}
                <div className="bg-gray-100 text-gray-800">
                    <div className="max-w-6xl mx-auto p-6 bg-white shadow-lg rounded-lg">
                        <h2 id="share" className="text-2xl font-semibold mb-4">
                        3. When and With Whom Do We Share Your Personal Information?
                        </h2>

                        <p className="mb-4">
                        We may share your personal information in the following situations:
                        </p>

                        <ul className="list-disc pl-6 mb-6">
                        <li>
                            <strong>Business Transfers:</strong> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
                        </li>
                        <li>
                            <strong>Affiliates:</strong> We may share your information with our affiliates, in which case we will require those affiliates to honor this Privacy Notice. Affiliates include our parent company and any subsidiaries, joint venture partners, or other companies that we control or that are under common control with us.
                        </li>
                        <li>
                            <strong>Other Users:</strong> When you share personal information (for example, by posting comments, contributions, or other content to the Services) or otherwise interact with public areas of the Services, such personal information may be viewed by all users and may be publicly made available outside the Services. If you interact with other users of our Services and register through a social network (such as Facebook), your contacts on the social network will see your name, profile photo, and descriptions of your activity. Similarly, other users will be able to view descriptions of your activity, communicate with you within our Services, and view your profile.
                        </li>
                        </ul>

                        
                        <p className="text-right text-blue hover:underline" onClick={() => scrollToTop()}>Back to top</p>
                        
                    </div>
                </div>

                {/* section 5 */}
                <div className="bg-gray-100 text-gray-800">
                    <div className="max-w-6xl mx-auto p-6 bg-white shadow-lg rounded-lg">
                        <h2 id="cookies" className="text-2xl font-semibold mb-4">
                        4. Do We Use Cookies and Other Tracking Technologies?
                        </h2>

                        <p className="mb-4">
                        Yes, we may use cookies and similar tracking technologies (like web beacons and pixels) to
                        access or store information. Specific information about how we use such technologies and how
                        you can refuse certain cookies is set out in our Cookie Notice.
                        </p>

                        <h3 className="text-xl font-semibold mb-4">Google Analytics</h3>
                        <p className="mb-4">
                        We may share your information with Google Analytics to track and analyze the use of the Services. To opt-out of being tracked by Google Analytics across the Services, you can visit the following link:
                        <a
                            href="https://tools.google.com/dlpage/gaoptout"
                            className="text-blue hover:underline"
                        >
                            https://tools.google.com/dlpage/gaoptout
                        </a>.
                        </p>

                        <p className="mb-4">
                        For more information on the privacy practices of Google, please visit the Google Privacy & Terms page:
                        <a
                            href="https://policies.google.com/privacy"
                            className="text-blue hover:underline"
                        >
                            https://policies.google.com/privacy
                        </a>.
                        </p>

                        
                        <p className="text-right text-blue hover:underline" onClick={() => scrollToTop()}>Back to top</p>
                        
                    </div>
                </div>

                {/* section 6 */}
                <div className="bg-gray-100 text-gray-800">
                    <div className="max-w-6xl mx-auto p-6 bg-white shadow-lg rounded-lg">
                        <h2 id="social-logins" className="text-2xl font-semibold mb-4">
                        5. How Do We Handle Your Social Logins?
                        </h2>

                        <p className="mb-4">
                        Our Services offer you the ability to register and log in using your third-party social media
                        account details (like your Facebook or X (formerly Twitter) logins). Where you choose to do
                        this, we will receive certain profile information about you from your social media provider.
                        </p>

                        <p className="mb-4">
                        The profile information we receive may vary depending on the social media provider concerned,
                        but will often include your name, email address, friends list, profile picture, and other information
                        you choose to make public on such a platform.
                        </p>

                        <p className="mb-6">
                        We will use the information we receive only for the purposes that are described in this Privacy
                        Notice or that are otherwise made clear to you on the relevant Services. Please note that we do
                        not control, and are not responsible for, other uses of your personal information by your third-party
                        social media provider. We recommend that you review their privacy notice to understand how
                        they collect, use, and share your personal information, and how you can set your privacy
                        preferences on their sites and apps.
                        </p>
                    </div>
                </div>

                <div className="bg-gray-100 text-gray-800">
                    <div className="max-w-6xl mx-auto p-6 bg-white shadow-lg rounded-lg">
                        <h2 id="retention" className="text-2xl font-semibold mb-4">
                        6. How Long Do We Keep Your Information?
                        </h2>

                        <p className="mb-4">
                        We will only keep your personal information for as long as it is necessary for the purposes set
                        out in this Privacy Notice, unless a longer retention period is required or permitted by law (such
                        as tax, accounting, or other legal requirements). No purpose in this notice will require us to keep
                        your personal information for longer than the period of time in which users have an account with
                        us.
                        </p>

                        <p className="mb-4">
                        When we have no ongoing legitimate business need to process your personal information, we
                        will either delete or anonymize such information, or, if this is not possible (for example, because
                        your personal information has been stored in backup archives), then we will securely store your
                        personal information and isolate it from any further processing until deletion is possible.
                        </p>

                        
                        <p className="text-right text-blue hover:underline" onClick={() => scrollToTop()}>Back to top</p>
                        
                    </div>
                </div>

                {/* section 7 */}
                <div className="bg-gray-100 text-gray-800">
                    <div className="max-w-6xl mx-auto p-6 bg-white shadow-lg rounded-lg">
                        <h2 id="safety" className="text-2xl font-semibold mb-4">
                        7. How Do We Keep Your Information Safe?
                        </h2>

                        <p className="mb-4">
                        We aim to protect your personal information through a system of organizational and technical
                        security measures. We have implemented appropriate and reasonable technical and
                        organizational security measures designed to protect the security of any personal information
                        we process. However, despite our safeguards and efforts to secure your information, no
                        electronic transmission over the Internet or information storage technology can be guaranteed
                        to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other
                        unauthorized third parties will not be able to defeat our security and improperly collect, access,
                        steal, or modify your information.
                        </p>

                        <p className="mb-4">
                        Although we will do our best to protect your personal information, transmission of personal
                        information to and from our Services is at your own risk. You should only access the Services
                        within a secure environment.
                        </p>
                    </div>
                </div>

                <div className="bg-gray-100 text-gray-800">
                    <div className="max-w-6xl mx-auto p-6 bg-white shadow-lg rounded-lg">
                        <h2 id="minors" className="text-2xl font-semibold mb-4">
                        8. Do We Collect Information from Minors?
                        </h2>

                        <p className="mb-4">
                        We do not knowingly collect data from or market to children under 18 years of age. By using
                        the Services, you represent that you are at least 18 or that you are the parent or guardian of
                        such a minor and consent to such minor dependent’s use of the Services.
                        </p>

                        <p className="mb-4">
                        If we learn that personal information from users less than 18 years of age has been collected, we
                        will deactivate the account and take reasonable measures to promptly delete such data from our
                        records. If you become aware of any data we may have collected from children under age 18,
                        please contact us at{' '}
                        <a href="mailto:support@amivaplus.com" className="text-blue hover:underline">
                            support@amivaplus.com
                        </a>.
                        </p>

                        
                        <p className="text-right text-blue hover:underline" onClick={() => scrollToTop()}>Back to top</p>
                        
                    </div>
                </div>

                {/* section 8 */}
                <div className="bg-gray-100 text-gray-800">
                    <div className="max-w-6xl mx-auto p-6 bg-white shadow-lg rounded-lg">
                        <h2 id="rights" className="text-2xl font-semibold mb-4">
                        9. What Are Your Privacy Rights?
                        </h2>

                        <p className="mb-4">
                        Depending on where you are located geographically, the applicable privacy law may mean you
                        have certain rights regarding your personal information. These rights may include the following:
                        </p>

                        <ul className="list-disc pl-6 mb-6">
                        <li>
                            <strong>Right to access:</strong> You may have the right to request access to the personal
                            information we collect from you, details about how we use it, and with whom we share it.
                        </li>
                        <li>
                            <strong>Right to correction:</strong> You may have the right to request that we correct or
                            update your personal information if it is inaccurate or incomplete.
                        </li>
                        <li>
                            <strong>Right to deletion:</strong> You may have the right to request the deletion of your
                            personal information under certain circumstances.
                        </li>
                        <li>
                            <strong>Right to restrict processing:</strong> You may have the right to request that we
                            restrict the processing of your personal information.
                        </li>
                        <li>
                            <strong>Right to data portability:</strong> You may have the right to request a copy of your
                            personal information in a structured, commonly used, and machine-readable format.
                        </li>
                        <li>
                            <strong>Right to withdraw consent:</strong> If we are relying on your consent to process your
                            personal information, you have the right to withdraw your consent at any time.
                        </li>
                        </ul>
                    </div>
                </div>


                <div className="bg-gray-100 text-gray-800">
                    <div className="max-w-6xl mx-auto p-6 bg-white shadow-lg rounded-lg">
                        <h2 id="dnt" className="text-2xl font-semibold mb-4">
                        10. Controls for Do-Not-Track Features
                        </h2>

                        <p className="mb-4">
                        Most web browsers and some mobile operating systems and mobile applications include a
                        Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not
                        to have data about your online browsing activities monitored and collected. At this stage, no
                        uniform technology standard for recognizing and implementing DNT signals has been finalized.
                        </p>

                        <p className="mb-4">
                        As such, we do not currently respond to DNT browser signals or any other mechanism that
                        automatically communicates your choice not to be tracked online. If a standard for online
                        tracking is adopted that we must follow in the future, we will inform you about that practice in
                        a revised version of this Privacy Notice.
                        </p>

                        
                        <p className="text-right text-blue hover:underline" onClick={() => scrollToTop()}>Back to top</p>
                        
                    </div>
                </div>

                {/* section 9 */}
                <div className="bg-gray-100 text-gray-800">
                    <div className="max-w-6xl mx-auto p-6 bg-white shadow-lg rounded-lg">
                        <h2 id="us-rights" className="text-2xl font-semibold mb-4">
                        11. Do United States Residents Have Specific Privacy Rights?
                        </h2>

                        <p className="mb-4">
                        In Short: Yes, if you are a resident of California, Colorado, Connecticut, Delaware, Florida,
                        Indiana, Iowa, Kentucky, Montana, New Hampshire, New Jersey, Oregon, Tennessee, Texas,
                        Utah, or Virginia, you may have the right to request access to and receive details about the
                        personal information we maintain about you and how we have processed it, correct
                        inaccuracies, get a copy of, or delete your personal information. You may also have the right to
                        withdraw your consent to our processing of your personal information.
                        </p>

                        <h3 className="text-xl font-semibold mb-4">Categories of Personal Information We Collect</h3>

                        <p className="mb-4">
                        We have collected the following categories of personal information in the past twelve (12) months:
                        </p>

                        <ul className="list-disc pl-6 mb-6">
                            <li><strong>A. Identifiers:</strong> Contact details, such as real name, alias, postal address, telephone or mobile contact number, email address, and account name.</li>
                            <li><strong>B. Personal information as defined in the California Customer Records statute:</strong> Name, contact information, education, employment, employment history, and financial information.</li>
                            <li><strong>C. Protected classification characteristics under state or federal law:</strong> Gender, age, date of birth, race and ethnicity, national origin, marital status, and other demographic data.</li>
                            <li><strong>D. Commercial information:</strong> Transaction information, purchase history, financial details, and payment information.</li>
                            <li><strong>E. Biometric information:</strong> Fingerprints and voiceprints.</li>
                            <li><strong>F. Internet or other similar network activity:</strong> Browsing history, search history, online behavior, interest data, and interactions with our websites, applications, systems, and advertisements.</li>
                            <li><strong>G. Geolocation data:</strong> Device location.</li>
                            <li><strong>H. Audio, electronic, sensory, or similar information:</strong> Images and audio, video, or call recordings created in connection with our business activities.</li>
                            <li><strong>I. Professional or employment-related information:</strong> Business contact details to provide services at a business level, job title, work history, and professional qualifications if you apply for a job with us.</li>
                            <li><strong>J. Education information:</strong> Student records and directory information.</li>
                            <li><strong>K. Inferences drawn from other personal information:</strong> Inferences drawn from any of the personal information listed above to create a profile about an individual’s preferences and characteristics.</li>
                            <li><strong>L. Sensitive personal information:</strong> Account login information.</li>
                        </ul>

                        <h3 className="text-xl font-semibold mb-4">How to Exercise Your Rights</h3>

                        <p className="mb-4">
                        To exercise these rights, you can contact us by visiting{' '}
                        <a href="https://app.amivaplus.com/users/my_profile" className="text-blue hover:underline">
                            https://app.amivaplus.com/users/my_profile
                        </a>, by emailing us at{' '}
                        <a href="mailto:support@amivaplus.com" className="text-blue hover:underline">
                            support@amivaplus.com
                        </a>, or by referring to the contact details at the bottom of this document.
                        </p>

                        <h3 className="text-xl font-semibold mb-4">Request Verification</h3>

                        <p className="mb-4">
                        Upon receiving your request, we will need to verify your identity to determine you are the same
                        person about whom we have the information in our system. We will only use personal
                        information provided in your request to verify your identity or authority to make the request.
                        </p>

                        
                        <p className="text-right text-blue hover:underline" onClick={() => scrollToTop()}>Back to top</p>
                        
                    </div>
                </div>

                {/* section 10 */}
                <div className="bg-gray-100 text-gray-800">
                    <div className="max-w-6xl mx-auto p-6 bg-white shadow-lg rounded-lg">
                        <h2 id="updates" className="text-2xl font-semibold mb-4">
                        12. Do We Make Updates to This Notice?
                        </h2>

                        <p className="mb-4">
                        In Short: Yes, we will update this notice as necessary to stay compliant with relevant laws.
                        </p>

                        <p className="mb-4">
                        We may update this Privacy Notice from time to time. The updated version will be indicated by
                        an updated "Revised" date at the top of this Privacy Notice. If we make material changes to this
                        Privacy Notice, we may notify you either by prominently posting a notice of such changes or by
                        directly sending you a notification. We encourage you to review this Privacy Notice frequently to
                        be informed of how we are protecting your information.
                        </p>
                    </div>
                </div>

                        
                <div className="bg-gray-100 text-gray-800">
                    <div className="max-w-6xl mx-auto p-6 bg-white shadow-lg rounded-lg">
                        <h2 id="contact" className="text-2xl font-semibold mb-4">
                        13. How Can You Contact Us About This Notice?
                        </h2>

                        <p className="mb-4">
                        If you have questions or comments about this notice, you may email us at{' '}
                        <a href="mailto:support@amivaplus.com" className="text-blue hover:underline">
                            support@amivaplus.com
                        </a> or contact us at:
                        </p>

                        <p className="mb-4">
                        AMIVA+, Inc.<br />
                        641 Lexington Ave 15th fl<br />
                        New York, NY 10022<br />
                        United States
                        </p>

                        
                        <p className="text-right text-blue hover:underline" onClick={() => scrollToTop()}>Back to top</p>
                        
                    </div>
                </div>

                {/* section 11 */}
                <div className="bg-gray-100 text-gray-800">
                    <div className="max-w-6xl mx-auto p-6 bg-white shadow-lg rounded-lg">
                        <h2 id="review" className="text-2xl font-semibold mb-4">
                        14. How Can You Review, Update, or Delete the Data We Collect From You?
                        </h2>

                        <p className="mb-4">
                        Based on the applicable laws of your country or state of residence, you may have the right to
                        request access to the personal information we collect from you, details about how we have
                        processed it, correct inaccuracies, or delete your personal information. You may also have the
                        right to withdraw your consent to our processing of your personal information. These rights may
                        be limited in some circumstances by applicable law.
                        </p>

                        <p className="mb-4">
                        To request to review, update, or delete your personal information, please visit:
                        <a
                            href="https://app.amivaplus.com/users/my_profile"
                            className="text-blue hover:underline"
                        >
                            https://app.amivaplus.com/users/my_profile
                        </a>, or contact us at: 
                        <a
                            href="mailto:support@amivaplus.com"
                            className="text-blue hover:underline"
                        >
                            support@amivaplus.com
                        </a>.

                        </p>

                        
                        <p className="text-right text-blue hover:underline" onClick={() => scrollToTop()}>Back to top</p>
                        
                    </div>
                </div>

                {/* section 12 */}





            </div>
        </form>

    </div>
  )
}
