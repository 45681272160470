import React, { useState, useEffect } from 'react';


export default function Typewriter({ text, speed }) {
  const [displayedText, setDisplayedText] = useState('');
  const [index, setIndex] = useState(0);

  useEffect(() => {
    setDisplayedText('');
    setIndex(0);
  }, [text]);

  useEffect(() => {
    if (index < text.length) {
      const interval = setInterval(() => {
        setDisplayedText((prev) => prev + text[index]);
        setIndex((prevIndex) => prevIndex + 1);
      }, speed);
      return () => clearInterval(interval);
    }
  }, [index, text, speed]);

  return <p className='text-5xl font-semibold home-sm:text-4xl'>
    {displayedText.split('\n').map((line, i) => (
        <React.Fragment key={i}>
          {line}
          <br />
        </React.Fragment>
      ))}
    </p>;
};