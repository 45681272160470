import React from 'react'

export default function CalendarItem({name, numberOfMonths, firstMonth, color}) {
    const monthOptions = [
        {value: 1, label: "Jan"},
        {value: 2, label: "Feb"},
        {value: 3, label: "Mar"},
        {value: 4, label: "Apr"},
        {value: 5, label: "May"},
        {value: 6, label: "Jun"},
        {value: 7, label: "Jul"},
        {value: 8, label: "Aug"},
        {value: 9, label: "Sep"},
        {value: 10, label: "Oct"},
        {value: 11, label: "Nov"},
        {value: 0, label: "Dec"},
    ]


    // Create an array of months, and set length of component
    const months = []
    for (let i = firstMonth; i < firstMonth + numberOfMonths; i++) {
        const month = monthOptions.find(month => month.value === i % 12)
        months.push(month.label)
    }

    const length = numberOfMonths*250
    
  return (
    <div className={`relative w-[${length}px]`}>
        <div className="flex items-center opacity-40">
        { months.map((month) => (
            <p key={month} className="w-[250px] border-l border-black pl-3 pb-12">
                {month}
            </p>
        ))}
        </div>

        <div className="flex flex-col justify-between p-8 bg-[#fbfbfb] mt-4 text-black text-opacity-40 rounded-md h-64">
        <p className="text-lg font-semibold">{name}</p>
        <p className="text-sm opacity-70">{numberOfMonths} {numberOfMonths > 1 ? 'months' : 'month'}</p>
        </div>

        <div className={`absolute bg-${color}-300 left-0 bottom-0 h-1 w-full rounded-b-md`}></div>
    </div>
  )
}
