import React from 'react';
import { useState, useEffect } from 'react';

const Stengths = ({features}) => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, [width]);

  return (
    <div className={`${width < 1200 ? (width < 800 ? 'grid-cols-1' : 'grid-cols-2') : 'grid-cols-3'} mx-10 max-w-1/3 mb-12 -mt-20 grid gap-4 items-center justify-center`}>
          {features.map((feature, index) => {
            const IconComponent = feature.icon;
            return (
              <div
                key={index}
                className={`
                flex flex-col items-center justify-center h-full relative px-8 rounded-md 
                overflow-hidden transform transition duration-500 hover:scale-101 hover:shadow-md drop-shadow-md`}
              >
                <div
                  className={`absolute inset-0 bg-white rounded`}
                ></div>
                <div className="relative z-10 flex items-center h-48">
                  <div className="p-4 bg-purple rounded-full shadow-md">
                    <IconComponent className="h-5 w-5 text-white bg-purple" />
                  </div>
                  <div className="ml-4">
                    <h3 className='font-semibold text-black mb-2 w-13 text-xl'>
                      {feature.title}
                    </h3>
                    <p className="text-black text-sm leading-relaxed">
                      {feature.description}
                    </p>
                  </div>
                </div>

                <div className='absolute bottom-0 w-full bg-purple h-1.5'></div>
              </div>
            );
          })}
      </div>
  );
};

export default Stengths;
