import React from "react";

export default function SellerCloseDeals() {
    return (
        <div className="max-w-[1250px] mx-auto px-12 py-24 text-center bg-gradient-to-r from-purple-50 to-purple-100 rounded-lg">
            <h1 className="text-4xl font-semibold max-w-[650px] mx-auto text-purple-900" data-aos="fade-up">
                To close deals successfully, you need software that works for you
            </h1>

            <p className="mt-20 text-lg font-semibold text-purple-700 opacity-70" data-aos="fade-up">
                Are you trying to manage deals but facing these challenges:
            </p>

            {/* grid with two columns, but if there is only one item in a row, it is centered */}
            <div className="flex flex-wrap justify-center gap-4 mt-20">
                <p className="px-5 py-4 text-base bg-white rounded-md shadow-md transition duration-300 ease-in-out transform flex-grow-0 flex-shrink-0 basis-[calc(50%-1rem)] max-w-[calc(50%-1.5rem)] m-2" data-aos="fade-up" style={{ 'textDecoration': 'underline', 'textDecorationColor': '#fdba74', 'textDecorationThickness': '2px' }}>
                    Spending more time on back office tasks than closing deals
                </p>
                <p className="px-5 py-4 text-base bg-white rounded-md shadow-md transition duration-300 ease-in-out transform flex-grow-0 flex-shrink-0 basis-[calc(50%-1rem)] max-w-[calc(50%-1.5rem)] m-2" data-aos="fade-up" style={{ 'textDecoration': 'underline', 'textDecorationColor': '#fdba74', 'textDecorationThickness': '2px' }}>
                    Losing deals due to unorganized processes
                </p>
                <p className="px-5 py-4 text-base bg-white rounded-md shadow-md transition duration-300 ease-in-out transform flex-grow-0 flex-shrink-0 basis-[calc(50%-1rem)] max-w-[calc(50%-1.5rem)] m-2" data-aos="fade-up" style={{ 'textDecoration': 'underline', 'textDecorationColor': '#fdba74', 'textDecorationThickness': '2px' }}>
                    Wasting money and time on multiple disconnected platforms
                </p>
                <p className="px-5 py-4 text-base bg-white rounded-md shadow-md transition duration-300 ease-in-out transform flex-grow-0 flex-shrink-0 basis-[calc(50%-1rem)] max-w-[calc(50%-1.5rem)] m-2" data-aos="fade-up" style={{ 'textDecoration': 'underline', 'textDecorationColor': '#fdba74', 'textDecorationThickness': '2px' }}>
                    Missing opportunities without automated deal tracking
                </p>
                <p className="px-5 py-4 text-base bg-white rounded-md shadow-md transition duration-300 ease-in-out transform flex-grow-0 flex-shrink-0 basis-[calc(50%-1rem)] max-w-[calc(50%-1.5rem)] m-2" data-aos="fade-up" style={{ 'textDecoration': 'underline', 'textDecorationColor': '#fdba74', 'textDecorationThickness': '2px' }}>
                    Struggling with manual tracking and buyer communication
                </p>
                <p className="px-5 py-4 text-base bg-white rounded-md shadow-md transition duration-300 ease-in-out transform flex-grow-0 flex-shrink-0 basis-[calc(50%-1rem)] max-w-[calc(50%-1.5rem)] m-2" data-aos="fade-up" style={{ 'textDecoration': 'underline', 'textDecorationColor': '#fdba74', 'textDecorationThickness': '2px' }}>
                    Overwhelmed by coordinating multiple deals and people
                </p>
                <p className="px-5 py-4 text-base bg-white rounded-md shadow-md transition duration-300 ease-in-out transform flex-grow-0 flex-shrink-0 basis-[calc(50%-1rem)] max-w-[calc(50%-1.5rem)] m-2" data-aos="fade-up" style={{ 'textDecoration': 'underline', 'textDecorationColor': '#fdba74', 'textDecorationThickness': '2px' }}>
                    Administrative tasks slowing down your deal-making process
                </p>
            </div>

            {/* Add the new button and text here */}
            <div className="mt-24 flex flex-col items-center">
                <a href="https://app.amivaplus.com/register?token=PuMG3da3LG0lD93100lkPQP" target="_blank" rel="noreferrer">
                    <div className="inline-flex items-center gap-2 px-6 py-3 bg-gradient-to-r from-black to-gray-800 rounded-full cursor-pointer group hover:shadow-lg hover:scale-105 transition border-2 border-transparent hover:duration-300 duration-300">
                        <p className="font-semibold text-sm md:text-base text-white">Upload your first deal</p>
                        <i className='bx bx-chevron-right text-white'></i>
                    </div>
                </a>
                <p className="mt-2 text-sm md:text-base text-black text-center">Easy, fast and free</p>
            </div>
        </div>
    );
}
