import React from "react";
import GifOne from '../../images/Comp_5.gif';
import GifTwo from '../../images/Comp_4.gif';
import GifThree from '../../images/Comp_2.gif';

export default function BuyerEasyWay() {
    return (
        <div className="w-full py-24">

        <div className="relative -top-[120px]" id="targetDiv"> </div>
            <div className="max-w-container mx-auto px-12 overflow-hidden">
                <h1 className="text-3xl font-semibold text-center text-purple-900" data-aos="fade-up">
                    We vet deals from top intermediaries, handle NDAs, manage data rooms,<br></br> and deliver them to your inbox. For <span className="underline decoration-blue-400">free.</span>
                </h1>

                <p className="text-center mt-20 text-lg" data-aos="fade-up">Our process is simple:</p>

                <div className="grid grid-cols-1 lg:grid-cols-2 mt-24 gap-24">
                    <div className="w-full rounded-md overflow-hidden" data-aos="fade-right">
                        <img src={GifOne} alt="gif" className="w-full h-full object-cover" />
                    </div>

                    <div className="self-center" data-aos="fade-left">
                        <p className="text-2xl font-semibold mb-2 text-purple-900">1. Create a FREE Account</p>
                        <p className="font-semibold opacity-70 mb-16 text-lg">Sign up with LinkedIn or manually</p>

                        <ul>
                            <li className="flex items-center gap-2 mb-2 text-purple-900"> 
                                <div className="w-4 h-4 bg-blue-300 rounded-full"></div>
                                Easy sign-up, absolutely free
                            </li>
                            <li className="flex items-center gap-2 mb-2 text-purple-900"> 
                                <div className="w-4 h-4 bg-blue-300 rounded-full"></div>
                                Quick access to the marketplace
                            </li>
                            <li className="flex items-center gap-2 mb-2 text-purple-900"> 
                                <div className="w-4 h-4 bg-blue-300 rounded-full"></div>
                                Start exploring deals immediately
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="grid grid-cols-1 lg:grid-cols-2 mt-24 gap-24">
                    <div className="justify-self-end self-center order-2 lg:order-1" data-aos="fade-right">
                        <p className="text-2xl font-semibold mb-2 text-purple-900">2. Explore the Marketplace</p>
                        <p className="font-semibold opacity-70 mb-16 text-lg max-w-lg">All opportunities from our community in one place</p>

                        <ul>
                            <li className="flex items-center gap-2 mb-2 text-purple-900"> 
                                <div className="w-4 h-4 bg-blue-300 rounded-full"></div>
                                Enter criteria for deals you want to review
                            </li>
                            <li className="flex items-center gap-2 mb-2 text-purple-900"> 
                                <div className="w-4 h-4 bg-blue-300 rounded-full"></div>
                                Sort using easy filter search
                            </li>
                            <li className="flex items-center gap-2 mb-2 text-purple-900"> 
                                <div className="w-4 h-4 bg-blue-300 rounded-full"></div>
                                Find opportunities that resonate
                            </li>
                        </ul>
                    </div>

                    <div className="w-full rounded-md overflow-hidden order-1 lg:order-2" data-aos="fade-left">
                        <img src={GifTwo} alt="gif" className="w-full h-full object-cover" />
                    </div>
                </div>

                <div className="grid grid-cols-1 lg:grid-cols-2 mt-24 gap-24">
                    <div className="w-full rounded-md overflow-hidden" data-aos="fade-right">
                        <img src={GifThree} alt="gif" className="w-full h-full object-cover" />
                    </div>

                    <div className="self-center" data-aos="fade-left">
                        <p className="text-2xl font-semibold mb-2 text-purple-900">3. Find Your Perfect Deal</p>
                        <p className="font-semibold opacity-70 mb-16 text-lg max-w-lg">Discover opportunities that align with your vision</p>

                        <ul>
                            <li className="flex items-center gap-2 mb-2 text-purple-900"> 
                                <div className="w-4 h-4 bg-blue-300 rounded-full"></div>
                                Instantly access data room after signing the NDA
                            </li>
                            <li className="flex items-center gap-2 mb-2 text-purple-900"> 
                                <div className="w-4 h-4 bg-blue-300 rounded-full"></div>
                                Identify the opportunity of your dreams
                            </li>
                            <li className="flex items-center gap-2 mb-2 text-purple-900"> 
                                <div className="w-4 h-4 bg-blue-300 rounded-full"></div>
                                Seamlessly navigate through curated deal flow
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="mt-24 flex flex-col items-center">
                    <a href="https://app.amivaplus.com/register?token=PuMG3da3LG0lD93100lkPQP" target="_blank" rel="noreferrer">
                        <div className="inline-flex items-center gap-2 px-6 py-3 bg-gradient-to-r from-black to-gray-800 rounded-full cursor-pointer group hover:shadow-lg hover:scale-105 transition border-2 border-transparent hover:duration-300 duration-300">
                            <p className="font-semibold text-sm md:text-base text-white">Experience the power of AMIVA+</p>
                            <i className='bx bx-chevron-right text-white'></i>
                        </div>
                    </a>
                    <p className="mt-2 text-sm md:text-base text-black text-center">Top vetted opportunities</p>
                </div>
            </div>
        </div>
    );
}
