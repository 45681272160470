import React from "react";
import dashboard from '../../images/dashboard.png'

export default function BuyerPromise() {
    return (
        <div className="pt-24 px-12" style={{ maxWidth: '1500px', margin: '0 auto' }} data-aos="fade-up">
            <div className="relative bg-purple rounded-md grid grid-cols-3 promise-md:grid-cols-1 gap-20 overflow-hidden" style={{ color: 'white', padding: '6rem 6rem 35rem', transition: 'transform 0.3s' }}>
                <div>
                    <h2 className="text-xl font-semibold mb-6">1. The Promise Delivered</h2>
                    <p>We’ve created the one-stop M&A platform you've been waiting for. From deal sourcing to closing, it’s all here.</p>
                </div>

                <div>
                    <h2 className="text-xl font-semibold mb-6">2. All-in-One Digital Hub</h2>
                    <p>Our cutting-edge technology integrates everything you need in one place, simplifying your entire deal-making process.</p>
                </div>

                <div>
                    <h2 className="text-xl font-semibold mb-6">3. Completely Free Access</h2>
                    <p>We’re thrilled to offer you full access to our platform at no cost—experience the future of deal-making today.</p>
                </div>

                <div className="absolute top-[15rem] left-1/2 -translate-x-1/2 w-[1200px] 
                promise-md:top-[38rem] promise-md:w-[900px] promise-sm:top-[38rem]">
                    <img src={dashboard} alt="dashboard" />
                </div>
            </div>
        </div>
    )
}