import React from "react";
import BuyerHome from "./BuyerHome";
import BuyerEasyWay from "./BuyerEsayWay";
import BuyerFeatures from "./BuyerFeatures";
import BuyerPromise from "./BuyerPromise";
import Strengths from '../general/Strengths';
import { 
    SearchCircleIcon,
    DocumentTextIcon,
    RefreshIcon
 } from '@heroicons/react/outline';


const Buyer = () => {

    const features = [
        {
          title: "AI-Powered Deal Sourcing",
          description: "Get matched with top-tier deals tailored to your investment strategy using our advanced AI",
          icon: SearchCircleIcon,
        },
        {
          title: "Streamlined Document Management",
          description: "Access and manage all deal documents efficiently in one integrated platform",
          icon: DocumentTextIcon,
        },
        {
          title: "Real-Time Deal Insights",
          description: "Track your entire acquisition process with live updates for maximum efficiency",
          icon: RefreshIcon,
        },
    ];

    return (
        <>
            <BuyerHome />
            <Strengths features={features} />
            <BuyerEasyWay />
            <BuyerFeatures />
            <BuyerPromise />
        </>
    )
}

export default Buyer;
